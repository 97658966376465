<template>
  <div>
    <div
      class="banner_info"
      style="
        background: url(images/8_02.jpg) center no-repeat;
        background-size: cover;
      "
    >
      <img src="images/8_03.png" />
    </div>
    <div class="infopage">
      <div class="container clearfix">
        <div class="left">
          <div class="box">
            <dl>
              <dt class="pt">功能菜单</dt>
            </dl>
            <ul>
              <li
                :class="{ on: index == cIndex }"
                @click="itemClick(index)"
                v-for="(item, index) in tabArray"
                :key="index"
              >
                <a href="javascript:;">{{ item }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="right" v-if="cIndex == 0">
          <div class="box">
            <div class="title flex">
              <div class="flex_item"><b>客户调查列表</b></div>
            </div>
            <div class="findlist">
              <div class="flex">
                <div class="select">
                  <select>
                    <option>调查表标题</option>
                  </select>
                </div>
                <div><input type="text" v-model="title" /></div>
                <button type="button" class="btn" @click="search">搜索</button>
              </div>
            </div>
            <div class="bd">
              <ul class="newslist">
                <li v-for="item in searchs" :key="item.id">
                  <div class="flex_item" @click="toDetail(item.id,'客户调查列表')"><a href="javascript:;" >{{item.title}}</a></div>
                  <span>{{item.isEnd == 1 ? "已结束" : item.endTime}}</span>
                </li>
              </ul>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[20, 40, 60, 80]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
            <div></div>
          </div>
        </div>

        <div class="right" v-else>
          <div class="box">
            <div class="title flex">
              <div class="flex_item"><b>客户留言</b></div>
            </div>
            <div class="form">
              <table width="100%">
                <tr>
                  <td width="85"><span class="redstar">*</span>姓名：</td>
                  <td>
                    <input type="text" class="input" v-model="comment.name" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>邮箱：</td>
                  <td >
                    <input type="text" class="input" v-model="comment.email" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>移动电话：</td>
                  <td >
                    <input
                      type="text"
                      class="input"
                      v-model="comment.telephone"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>主题：</td>
                  <td >
                    <input type="text" class="input" v-model="comment.title" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>内容：</td>
                  <td>
                    <textarea
                      style="border: 1px solid #d8eaff; background: #f5f5f6"
                      cols="60"
                      rows="10"
                      v-model="comment.content"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type="button" class="btn" @click="send">
                      发送
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customer",
  data() {
    return {
      cIndex: 0,
      searchs: [],
      title: "",
      page: 1,
      size: 20,
      total: 0,
      tabArray: ["客户调查列表", "客户留言"],
      comment: {
        name: "",
        telephone: "",
        email: "",
        title: "",
        content: "",
      },
    };
  },
  mounted() {
    this.getSearchColumn();
  },
  methods: {
    async getSearchColumn() {
      let res = await this.$api.searchColumn({
        title: this.title,
        columnName: "客户调查列表",
        page: this.page,
        size: this.size,
      });
      console.log(res.data.data)
      this.searchs = res.data.data.data;
      this.total = res.data.data.totalPage;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getSearchColumn();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getSearchColumn();
    },
    toDetail(id,name){
      this.$router.push({path:'/question',query:{articleId:id,name:name}})
    },
    search() {
      this.getSearchColumn();
    },
    itemClick(index) {
      this.cIndex = index;
      console.log(this.cIndex);
    },
    async send() {
      let res = await this.$api.create(this.comment);
      if (res.data.code == 200) {
        this.$message({
          message: "留言成功",
          type: "success",
        });
        for (let item in this.comment) {
          this.comment[item] = "";
        }
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
};
</script>

<style scoped>
</style>
